import React from 'react';

import { ShopPage } from '@kali/theme/lib/pages/shop/page';
import Layout from '../components/layout';

export default class ShopRoute extends React.Component {
  state = {
    showFrame: '',
    value: 100,
  };

  componentDidMount() {
    window.addEventListener('message', e => {
      if (e.data === 'closeshop') {
        this.setState({
          showFrame: '',
        });
      }
    });
  }

  onChange = value => {
    this.setState({
      value,
    });
  };

  onSubmit = () => {
    const { value } = this.state;
    this.setState({
      showFrame: `https://us-central1-kalina-2ab49.cloudfunctions.net/api/shop.html?value=${value}#${
        window.location.origin
      }`,
    });
  };

  render() {
    const { showFrame } = this.state;
    return (
      <Layout title="Shop">
        <ShopPage
          showFrame={showFrame}
          onChange={this.onChange}
          onSubmit={this.onSubmit}
        />
      </Layout>
    );
  }
}
